console.log('Vite ⚡️ Rails')

import '@hotwired/turbo-rails'

import 'fslightbox'

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

const channels = import.meta.glob('./**/*_channel.js',{ eager: true })

// animate on scroll
import AOS from 'aos'
window.AOS = AOS

// typeahead
import Typed from 'typed.js'
window.Typed = Typed

// front theme code
import {HSCore} from '../vendor/theme.min'
window.HSCore = HSCore

import HSGoTo from '../vendor/hs-go-to/src/js/hs-go-to.js'
window.HSGoTo = HSGoTo

import HSShowAnimation from '../vendor/hs-show-animation/src/js/hs-show-animation.js'
window.HSShowAnimation = HSShowAnimation

import HSMegaMenu from '../vendor/hs-mega-menu/src/js/hs-mega-menu.js'
window.HSMegaMenu = HSMegaMenu

import HSToggleSwitch from '../vendor/hs-toggle-switch/src/js/hs-toggle-switch.js'
window.HSToggleSwitch = HSToggleSwitch

// bootstrap 5
import * as bootstrap from 'bootstrap'

import 'chartkick/chart.js'

// stimulus controllers
import '../controllers'
