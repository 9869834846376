import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["learn", "meet", "achieve"];

  connect() {
    // console.log("filter ready for duty")
  }

  learnFilter(event) {
    this.show(this.learnTargets)
    this.hide(this.meetTargets)
    this.hide(this.achieveTargets)
  }

  meetFilter(event) {
    this.show(this.meetTargets)
    this.hide(this.learnTargets)
    this.hide(this.achieveTargets)
  }

  achieveFilter(event) {
    this.show(this.achieveTargets)
    this.hide(this.learnTargets)
    this.hide(this.meetTargets)
  }

  reset(event) {
    this.show(this.learnTargets)
    this.show(this.meetTargets)
    this.show(this.achieveTargets)
  }

  // helpers
  hide(targets) {
    targets.forEach( t => {
      t.classList.add('d-none')
    })
  }

  show(targets) {
    targets.forEach( t => {
      t.classList.remove('d-none')
    })
  }

}
